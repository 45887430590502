@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Bungee+Shade&family=Raleway:wght@100&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body{
    height: 100%;
}

.webgl{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.switch {
  position: absolute;
  top: -0.5rem;
  right: -1.5rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: rgba(0, 255, 255, 0.349);
  transition: all .4s ease;
  text-align: center;
  line-height: 2.3rem;
  font-family: fantasy;
  font-size: 0.85rem;
  border: 1px solid #ffffffec;
  color: rgba(255, 255, 255, 0.747);
}

.switch:hover{
    cursor: pointer;
    transform: translateZ(3px);
    transform: rotateY(360deg);
    background-color: rgba(104, 138, 138, 0.315);
    box-shadow: 0px 15px 20px rgba(13, 19, 16, 0.4);
    border: none;
    color: rgb(93, 214, 198);
}

.switch:active{
    transition: all .1s;
    transform: translateY(4px);
    background-color: white;
    color:black;
}

ul{

margin-top: 1rem;
padding:0.25rem;
clip-path: polygon(80% 0, 100% 10%, 100% 100%, 0 100%, 0 0);
background-color: rgba(104, 138, 138, 0.315);
}

li {
font-family:monospace;
text-transform: uppercase;
position: relative;
display: block;
height: 100%;
width: 7rem;
border: 3px solid rgba(109, 194, 169, 0.453);
background-color: rgba(43, 90, 86, 0.281);
margin: 0.6rem;
}


ul > li::after {

opacity: 0;
display: block;
content: "";
width: 1rem;
height: 1rem;
position: absolute;
top: 2rem;
right: 2rem;
border-top: 3px solid rgba(117, 226, 245, 0.753);
border-right: 3px solid rgba(117, 226, 245, 0.753);
transition: all .38s ease;
}

ul > li::before {
opacity: 0;
display: block;
content: "";
width: 1rem;
height: 1rem;
position: absolute;
bottom: 1rem;
left: 1.5rem;
border-bottom: 3px solid rgba(117, 226, 245, 0.753);
border-left: 3px solid rgba(117, 226, 245, 0.753);
transition: all .38s ease;
} 

ul > li > a{
text-decoration: none;  
display: block;
padding: 0.5rem;
color:rgb(93, 214, 198);
}

ul > li:hover::before {
color: whitesmoke;
opacity: 1;
bottom: -.5rem;
left: -.5rem;
}

ul > li:hover::after {
opacity: 1;
top: -.5rem;
right: -.5rem; 
}

ul > li:hover a{
color: white;

}

ul > li:last-child a, li:first-child a{
color: rgb(255, 255, 255);
cursor: default;
}
  
#firstA{
  font-size: 0.6rem;
}
  
.container-main{
    height: 100%;
    padding: 1.8rem;
}
  
.container{
  padding: 0.3rem;
}

.container-nav{
    margin-top: 3rem;
    width: 15%;
    display: flex;
    justify-content: left;
    background-color: transparent;
    }

.container-content{
    position: relative;
    width: 85%;
    background-color: rgba(81,125,112,0.85);
}

.container-nav-content{
    position: relative;
}


.container h3{
    text-align: center;
    font-family: 'Raleway', sans-serif;
  }

  .note{
    position: absolute;
    top:0;
    right:0;
    border: 1px solid ;
    background-color:  rgba(117, 226, 245, 0.555);
    z-index: 2;
  }
/* loading.io/css */

  .lds-roller {
    font-family: 'Bungee Shade', sans-serif;
    color:rgb(93, 214, 198);
    position: relative;
    top: 40%;
    left: 52%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    font-size: 3.5rem;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
    position: absolute;
    right: 50%;
  }
  .lds-roller div::after {
    content: "";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    right: 50%;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1)::after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2)::after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3)::after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5)::after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6)::after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7)::after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8)::after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }