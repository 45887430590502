@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Raleway:wght@400&display=swap");

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: rgba(104, 138, 138, 0.315);
  height: 100%;
}

.header {
  font-family: "Alex Brush", sans-serif;
  text-align: center;
  font-size: 3rem;
  color: #e4717a;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
  border-bottom: 1px solid #e4717a;
  margin-bottom: 2rem;
}

.head {
  writing-mode: vertical-lr;
  font-size: 1.2rem;
  margin-right: 1rem;
  border-top: 1px solid #e4717a;
  border-bottom: 1px solid #e4717a;
  border-radius: 25%;
  padding: 3.5px;
}

.body-s {
  display: flex;
  margin-bottom: 1rem;
}

.para {
  font-family: "Raleway", sans-serif;
  border-bottom: 2px dashed #f89da5a8;
}

.par {
  font-family: "Raleway", sans-serif;
}

.iframe_body {
  min-height: 100vh;
}

.code_box {
  display: block;
  border: 1px solid black;
  background-color: #74cbd169;
  width: 100%;
  overflow: auto;
  padding: 1rem;
  border-radius: 1.6rem;
}

.main_body h3 {
  display: inline;
}

.main_body h3:hover a {
  color: #e4717a;
}

.main_body {
  padding: 0.5rem;
}

.cdbtn {
  font-family: "Raleway", sans-serif;
  font-size: 1.1rem;
  color: black;
  display: inline-block;
  border: 1px solid #eb4c5a;
  margin: 0 1rem;
  background-color: #0000003d;
  border-radius: 8px;
  padding: 0.4rem 0.15em;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

a {
  text-decoration: none;
  color: #000000;
  font-style: bold;
  border-radius: 7px;
  /* transition: border color 1s ease; */
}

.cdbtn:hover {
  border-color: #000000;
}

.head a:hover,
.head a:active {
  color: #ffffffbe;
  background-color: rgba(54, 73, 73, 0.089);
  transition: all 0.3s ease;
}

.challenges_class {
  display: flex;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.challenges_class img {
  border-radius: 50%;
  width: 12rem;
  margin: 0 3rem;
  height: 12rem;
  transition: all 0.15s ease-in;
}

.challenges_class img:hover {
  transform: scale(1.3);
}
